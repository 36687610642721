const {
  ORDER_STATUS,
  AMOUNT_TYPE,
  WARRANTY_BILL_STATUS,
  PAYMENT_STATUS,
  STOCK_HISTORY,
} = require('./enum');

module.exports.ORDER_STATUS_NAME = Object.freeze({
  [ORDER_STATUS.NEW]: 'Mới',
  [ORDER_STATUS.CONFIRMING]: 'Đang xử lí',
  [ORDER_STATUS.CONFIRMED]: 'Đã xác nhận',
  [ORDER_STATUS.DELIVERY]: 'Đang giao',
  [ORDER_STATUS.PACKING]: 'Đang đóng gói',
  [ORDER_STATUS.SUCCESS]: 'Thành công',
  [ORDER_STATUS.UN_SUCCESS]: 'Không thành công',
  [ORDER_STATUS.SYSTEM_CANCEL]: 'Hệ thống hủy',
  [ORDER_STATUS.REFUNDED]: 'Đã chuyển hoàn',
  [ORDER_STATUS.NO_CATCHED_PHONE]: 'Không bắt máy',
  [ORDER_STATUS.CR_CANCEL]: 'CR huỷ',
  [ORDER_STATUS.DEPOSIT]: 'Đã đặt cọc',
  [ORDER_STATUS.AGREE_DEPOSIT]: 'Đồng ý cọc',
  [ORDER_STATUS.PREPARED]: 'Đã chuẩn bị',
  [ORDER_STATUS.TRACKING]: 'Đang theo dõi',
  [ORDER_STATUS.KEEP_DEPOSIT]: 'Giữ cọc',
});

module.exports.BILL_STATUS_NAME = Object.freeze({
  [WARRANTY_BILL_STATUS.NEW]: 'Mới',
  [WARRANTY_BILL_STATUS.PROCESSING]: 'Đang xử lý',
  [WARRANTY_BILL_STATUS.DONE]: 'Đã trả bảo hành',
});

module.exports.DISCOUNT_AMOUNT_TYPE_NAME = Object.freeze({
  [AMOUNT_TYPE.MONEY]: '$',
  [AMOUNT_TYPE.PERCENT]: '%',
});

module.exports.PAYMENT_STATUS_NAME = Object.freeze({
  [PAYMENT_STATUS.SUCCESS]: 'Đã thanh toán',
  [PAYMENT_STATUS.FAILED]: 'Chưa thanh toán',
});

module.exports.STOCK_HISTORY_NAME = Object.freeze({
  [STOCK_HISTORY.IMPORT_STOCK]: 'Nhập kho',
  [STOCK_HISTORY.EXPORT_STOCK]: 'Xuất kho',
  [STOCK_HISTORY.TRANSFER_STOCK]: 'Chuyển kho',
  [STOCK_HISTORY.UPDATE_IMPORT_STOCK]: 'Cập nhật phiếu nhập kho',
  [STOCK_HISTORY.UPDATE_EXPORT_STOCK]: 'Cập nhật phiếu xuất kho',
  [STOCK_HISTORY.EXPORT_TO_TRANSFER]: 'Xuất chuyển kho',
  [STOCK_HISTORY.IMPORT_FROM_TRANSFER]: 'Nhập chuyển kho',
  [STOCK_HISTORY.EXPORT_TO_SALE_RETAIL]: 'Xuất kho bán lẻ',
  [STOCK_HISTORY.EXPORT_TO_SALE_WHOLE_SASE]: 'Xuất kho bán buôn',
  [STOCK_HISTORY.IMPORT_FROM_RETURN_BILL]: 'Nhập từ phiếu trả hàng',
  [STOCK_HISTORY.IMPORT_FROM_REFUND_ORDER]: 'Nhập từ đơn chuyển hoàn',
  [STOCK_HISTORY.EXPORT_TO_SHIPPING]: 'Xuất kho giao hàng',
  [STOCK_HISTORY.INVENTORY_BALANCE]: 'Cân kho',
  [STOCK_HISTORY.IMPORT_WARRANTY]: 'Nhập kho bảo hành',
  [STOCK_HISTORY.UPDATE_IMPORT_WARRANTY]: 'Cập nhật nhập kho bảo hành',
  [STOCK_HISTORY.EXPORT_WARRANTY]: 'Xuất kho bảo hành',
  [STOCK_HISTORY.UPDATE_EXPORT_WARRANTY]: 'Cập nhật xuất kho bảo hành',
  [STOCK_HISTORY.CHANGE_PRODUCT_IMEI]: 'Đổi sản phẩm',
  [STOCK_HISTORY.REMOVE_FROM_BILL]: 'Xóa sản phẩm trong hóa đơn',
  [STOCK_HISTORY.REMOVE_BILL]: 'Xóa hóa đơn',
  [STOCK_HISTORY.REMOVE_RETURN_BILL]: 'Xóa hóa đơn trả hàng',
  [STOCK_HISTORY.UPDATE_EXPORT_TO_SALE]: 'Cập nhật hóa đơn',
  [STOCK_HISTORY.DELETE_TRANSFER_STOCK]: 'Xóa phiếu chuyển kho',
  [STOCK_HISTORY.DELETE_IMPORT_STOCK]: 'Xóa phiếu nhập',
  [STOCK_HISTORY.DELETE_EXPORT_STOCK]: 'Xóa phiếu xuất',
  [STOCK_HISTORY.IMPORT_FROM_TRADE_IN]: 'Nhập kho thu cũ',
  [STOCK_HISTORY.CANCEL_TRANSFER]: 'Hủy phiếu chuyển',
  [STOCK_HISTORY.IMPORT_HOLDING]: 'Nhập kho giữ hàng',
  [STOCK_HISTORY.UPDATE_IMPORT_HOLDING]: 'Cập nhật nhập kho giữ hàng',
  [STOCK_HISTORY.DELETE_IMPORT_HOLDING]: 'Xóa phiếu nhập kho giữ hàng',
  [STOCK_HISTORY.EXPORT_HOLDING]: 'Xuất trả giữ hàng',
  [STOCK_HISTORY.DELETE_EXPORT_HOLDING]: 'Xóa phiếu xuất trả giữ hàng',
  [STOCK_HISTORY.CANCEL_HOLDING]: 'Hủy phiếu giữ hàng',
  [STOCK_HISTORY.DELETE_IMPORT_WARRANTY]: 'Xoá phiếu nhập bảo hành',
  [STOCK_HISTORY.DELETE_EXPORT_WARRANTY]: 'Xoá phiếu xuất bảo hành',
  [STOCK_HISTORY.DELETE_IMPORT_TRADE_IN]: 'Xoá phiếu nhập thu cũ',
  [STOCK_HISTORY.IMPORT_WARRANTY_FROM_BILL]: 'Nhập kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.EXPORT_WARRANTY_FROM_BILL]: 'Xuất kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.UPDATE_IMPORT_WARRANTY_FROM_BILL]:
    'Cập nhật nhập kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.UPDATE_EXPORT_WARRANTY_FROM_BILL]:
    'Cập nhật xuất kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.DELETE_IMPORT_WARRANTY_FROM_BILL]:
    'Xóa phiếu nhập kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.DELETE_EXPORT_WARRANTY_FROM_BILL]:
    'Xóa phiếu xuất kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.EXPORT_STOCK_BY_WARRANTY_BILL]:
    'Xuất kho bởi đổi sp từ hóa đơn bảo hành',
  [STOCK_HISTORY.DELETE_REQUIRE_EXPORT_WARRANTY_STOCK]:
    'Xóa phiếu yêu cầu xuất nhập kho bảo hành',
  [STOCK_HISTORY.EXPORT_WARRANTY_TO_PROVIDER]: 'Xuất kho trả nhà cung cấp',
  [STOCK_HISTORY.UPDATE_EXPORT_WARRANTY_TO_PROVIDER]:
    'Xập nhật xuất kho trả nhà cung cấp',
  [STOCK_HISTORY.DELETE_EXPORT_WARRANTY_TO_PROVIDER]:
    'Xoá phiếu xuất kho trả nhà cung cấp',
  [STOCK_HISTORY.IMPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Nhập kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.EXPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Xuất kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.CREATE_WARRANTY_COMPLETION_SLIP]:
    'Tạo phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.DELETE_WARRANTY_COMPLETION_SLIP]:
    'Xoá phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.DELETE_IMPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Xoá phiếu nhập kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.DELETE_EXPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Xoá phiếu xuất kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.EXPORT_WARRANTY_STOCK_BY_DELETE_WARRANTY_BILL]:
    'Xuất kho bảo hành bởi xoá hoá đơn bảo hành',
  [STOCK_HISTORY.IMPORT_STOCK_BY_DELETE_WARRANTY_BILL]:
    'Nhập kho bởi xoá hoá đơn bảo hành',
  [STOCK_HISTORY.IMPORT_STOCK_BY_DELETE_STORE_WARRANTY_BILL]:
    'Nhập kho bởi xoá hoá đơn bảo hành (lỗi cửa hàng)',
  [STOCK_HISTORY.EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL]:
    'Xuất kho bởi tạo hoá đơn bảo hành (lỗi cửa hàng)',
  [STOCK_HISTORY.DELETE_EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL]:
    'Nhập kho bởi xoá hoá đơn bảo hành (lỗi cửa hàng)',
});
